<template>
    <div>
        <gmap-map
            :center="center"
            :zoom="12"
            style="width:100vw;  height: calc(100vh - 56px);"
            :options="{disableDefaultUI:true}">
            <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m"></gmap-marker>
        </gmap-map>
    </div>
</template>

<script>
export default {
    data(){
        return {
            center: {
                lat: 38.4653076,
                lng: 27.3498951
            },
        }
    },


    computed: {
        markers(){
            var bolgeler = this.$store.state.bolgeler.filter(c => c.bolge_belediye == this.$store.state.user.user_id).map(c => {
                return c.bolge_id;
            });
            var markers = this.$store.state.konteynerlar.filter(c => {
                if(bolgeler.includes(c.kk_bolge)){
                    return c;
                }
            }).map(c => {
                return {
                    lat: Number(c.kk_lat),
                    lng: Number(c.kk_lng),
                    kk_id: c.kk_id,
                    kk_bolge: c.kk_bolge,
                    order: c.kk_order,
                    info: false,
                    data: c.data
                }
            });
            return markers
            // var bolgeler = this.$store.state.bolgeler.filter(c => c.bolge_belediye == this.$store.state.user.user_id);
            // var data = [];
            // bolgeler.forEach(r => {
            //     data = this.$store.state.konteynerlar.map(c => {
            //         var d = null;
            //         if(c.kk_bolge == r.bolge_id){
            //             d = {
            //                 lat: Number(c.kk_lat),
            //                 lng: Number(c.kk_lng),
            //                 kk_id: c.kk_id,
            //                 kk_bolge: c.kk_bolge,
            //                 order: c.kk_order,
            //                 info: false,
            //                 data: c.data
            //             }
            //         }
            //         return d;
            //     });
            // });
            //return data;
        }
    },

}
</script>